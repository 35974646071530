@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/* @import url('https://fonts.googleapis.com/css2?family=Mate+SC&display=swap'); */
h1 { 
    text-align: center;
}

*{
    font-family: 'Roboto'
  }

.landing-link:hover{
    text-decoration: none !important;
  }
.icon-popup{
    color: white;
}  
.landing-name {
    font-size:70px;
    color: #363837;
    transition-duration: 250ms;
  }

  .landing-name:hover {
    color:white;
  }

.nav-links{
      margin: 2em;
      padding: .5em;
      font-size: 18px;
      background-color: #222222;
      border-radius: 4px;
  }

.image-container {
    display: flex;
    justify-content: center;
    padding: 0 3em;
}

.image-container img {
    height: 650px;
    max-width: 300px;
    display: block;
    object-fit: cover;
    transition: all .2s ease-in-out;
    /* z-index: 1; */
    
}
.form-container {
    margin: auto;
    width: 70%;
    padding: 2em;
    background-color: #333333;
    border-radius: 14px;
}
.contact-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.contact-form input, textarea{
    margin: 1.5em 0 0 0;
}


.image-container img:hover {
    transform: scale(1.1);
    font-size: 3em;
    z-index: 4;
    position: relative;  
}

.projects-container {
    width: 72%;
    display: flex;
    flex-wrap: nowrap;
    /* justify-content: center; */
    overflow-x: auto;
    margin: 0 0 1em 0;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    
}

.mobile-projects-container {
    width: 90%;
    display: flex;
    flex-wrap: nowrap;
    /* justify-content: center; */
    overflow-x: auto;
    margin: 0 0 1em 0;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
    
}

.projects-container::-webkit-scrollbar {
    display: none;
  }

.mobile-project-container {
    min-width: 325px;
    height: 700px;
    text-align: center;
    margin: 0 0 2em 0;
    padding-bottom: 5em;
}

.project-component-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1 1;
}
.nav-button{
    margin: 0;
    color: black;
}
.nav-button:hover{
    cursor: pointer;
    color: white;
    transition-duration: .4s;
}

.project-container {
    min-width: 30em;
    height: 700px;
    text-align: center;
    margin: 0 .8em 1em;
    padding-bottom: 2em;
    border-radius: 12px;
}


.image-links {
    position: relative;
    text-align: center;
    
}

.image-links h2 {
    position: absolute;
    z-index:5 ; 
    color: white;

}

.mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.mobile img{
    min-height: 400px;
    max-width: 300px;
    display: block;
    object-fit: cover;
    transition: all .2s ease-in-out;
    
}

.mobile button{
    margin: 2em 0;

}

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(25 53 81);
    color: rgb(245 155 13);
}

.main2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #222222;
    color: white;
}

.content{
    margin-top: 7em;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.content2{
    margin-top: 7em;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
}
.content button{
    margin: 0 2em 3em;
    background-color: rgb(245 155 13);
    width: 8em;
}

.content2 button{
    margin: 0 .5em 3em;
    width: 8em;
}
.content img{
    margin: 0;
    height: 30em;
}

.info-card {
    width: 25em;
    border: 2px solid rgb(245 155 13);
    padding: 2em;
    
}

.info-card2 {
    width: 25em;
    border: 2px solid white;
    padding: 2em;
    background-color: #375A7F;
    
}

.info-card h1{
    margin: 0 -20px .5em
}


