
.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgb(25 53 81);
    color: rgb(245 155 13);
}

.main2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #222222;
    color: white;
}

.content{
    margin-top: 7em;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.content2{
    margin-top: 7em;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    
}
.content button{
    margin: 0 2em 3em;
    background-color: rgb(245 155 13);
    width: 8em;
}

.content2 button{
    margin: 0 .5em 3em;
    width: 8em;
}
.content img{
    margin: 0;
    height: 30em;
}

.info-card {
    width: 25em;
    border: 2px solid rgb(245 155 13);
    padding: 2em;
    
}

.info-card2 {
    width: 25em;
    border: 2px solid white;
    padding: 2em;
    background-color: #375A7F;
    
}

.info-card h1{
    margin: 0 -20px .5em
}

